<div class="navBar" [class]="topnavTo?'topnav topnavResponse':'topnav'" id="myTopnav" style="z-index: 100;">
  <a routerLink="/home" id="homeLogo" class="nav" (click)="onClick(1)">PM Studio</a>
  <a routerLink="/home" routerLinkActive="active" (click)="onClick(1);" style="text-shadow: 1px 1px 1px gray;">首頁</a>
  <a routerLink="/aboutus" routerLinkActive="active" (click)="onClick(2)"
    style="text-shadow: 1px 1px 1px gray;">關於我們</a>
  <a routerLink="/service" routerLinkActive="active" (click)="onClick(3)"
    style="text-shadow: 1px 1px 1px gray;">服務項目</a>
  <a routerLink="/portfolio" routerLinkActive="active" (click)="onClick(4)" style="text-shadow: 1px 1px 1px gray;"
    (mouseup)="refresh()">作品集</a>
  <a routerLink="/qanda" routerLinkActive="active" (click)="onClick(5)" style="text-shadow: 1px 1px 1px gray;">常見
    Q&A</a>
  <a routerLink="/contactus" routerLinkActive="active" (click)="onClick(6)"
    style="text-shadow: 1px 1px 1px gray;">聯絡我們</a>
  <a href="javascript:void(0);" class="icon" (click)="navToggle()">
    <i class="fas fa-bars" style="color: white;text-shadow: 1px 1px 1px gray;"></i>
  </a>
  <div id="navInfoId" [class]="navInfoTo?'navInfo navInfoShow':'navInfo'" style="position: relative;">
    <hr style="width: 70%;background-color:#E3C7A4;margin-top: 0.2rem;margin-bottom: 0.3rem;">
    <div class="navInfoItem">
      <div style="width:10rem;">
        <div
          style="width: 34px;height:34px;background-image: url(../assets/images/icon/facebook_brown.png);vertical-align: middle;display: inline-block;background-repeat: no-repeat;">
        </div>
        <span style="padding-left: 0.3rem;font-size: 0.8rem;color: #f3dec3;">Facebook
          粉絲團
        </span>
      </div>
      <div style="width:10rem;">
        <div style="width: 34px;height:34px;vertical-align: middle;display: inline-block;">
        </div>
        <a href="#" class="color02 "
          style="padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;margin-top: 0;padding:0;padding-left: 0.3rem;">PM
          Baby 新生兒寫真 </a>
      </div>
      <div style="width:10rem;">
        <div style="width: 34px;height:34px;vertical-align: middle;display: inline-block;">
        </div>
        <a href="#" class="color02 "
          style="padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;margin-top: 0;padding:0;padding-left: 0.3rem;">PM
          Studio 婚紗攝影 </a>
      </div>
    </div>
    <div class="navInfoItem">
      <div style="width:10rem;">
        <div
          style="width: 34px;height:34px;background-image: url(../assets/images/icon/instagram_brown.png);vertical-align: middle;display: inline-block;background-repeat: no-repeat;">
        </div>
        <span style="padding-left: 0.3rem;font-size: 0.8rem;color: #f3dec3;">instagram</span>
      </div>
      <div style="width:10rem;">
        <div style="width: 34px;height:34px;vertical-align: middle;display: inline-block;">
        </div>
        <a href="#" class="color02 "
          style="padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;margin-top: 0;padding:0;padding-left: 0.3rem;">PM
          Baby 新生兒寫真 </a>
      </div>
      <div style="width:10rem;">
        <div style="width: 34px;height:34px;vertical-align: middle;display: inline-block;">
        </div>
        <a href="#" class="color02 "
          style="padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;margin-top: 0;padding:0;padding-left: 0.3rem;">PM
          Studio 婚紗攝影 </a>
      </div>
    </div>

    <p
      style="text-align: center; color: #e3c7a4;font-size: 0.7rem;bottom: 0;position: absolute;text-align: center;width: 100%;">
      Copyright © PM Studio
      All Rights
      Reserved.</p>
  </div>
</div>


<router-outlet></router-outlet>